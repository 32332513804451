/* eslint-disable no-undef */

// Externals
import {getBackendUrl, getDeskopSharingSubdomain} from '@techsee/techsee-common/lib/utils';
import {TsEnvironmentDetect, ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {prgUrlParamName } from '@techsee/techsee-common/lib/constants/pending-room.constants';
import {DesktopShareService, IDesktopShareService} from '@techsee/techsee-client-infra/lib/services/DesktopShareService';
import {BrowserUtilsService, IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

// Controllers
import {StartController, IStartController} from './states/Start/Start.controller';

// Services
import {BrandingService, IBrandingService} from './services/BrandingService';
import {BrowserDetectService, IBrowserDetectService} from './services/BrowserDetectService';
import {WithBrandingController, IWithBrandingController} from './components/with-branding/controller';
import {IRoomService, RoomService} from '@techsee/techsee-client-infra/lib/services/RoomService';
import {IEventLogService, EventLogService} from '@techsee/techsee-client-infra/lib/services/EventLogService';
import {ExpiredController, IExpiredController} from './states/Expired/Expired.controller';

export interface IAppStore {
    readonly startController: IStartController,
    readonly expiredController: IExpiredController,
    readonly withBrandingController: IWithBrandingController,
    readonly subDomain: string
}

export class AppStore implements IAppStore {
    private _startController: IStartController;
    private _expiredController: IExpiredController;
    private _withBrandingController: IWithBrandingController;
    private _apiService: IDesktopShareService;
    private _roomService: IRoomService;
    private _brandingService: IBrandingService;
    private _browserUtilsService: IBrowserUtilsService;
    private _tsEnvironmentDetect: ITsEnvironmentDetect;
    private _browserDetectService: IBrowserDetectService;
    private _eventLogService: IEventLogService;

    constructor() {
        const backendUrl = window.location.origin.indexOf('localhost') !== -1
            ? 'http://localhost:3001/'
            : getBackendUrl('https://{hostname}/', {
                hostname: window.location.hostname,
                ENV: {cordova: false}});
        const statsApiUrl = window.location.origin.indexOf('localhost') !== -1
            ? 'http://localhost:3001/'
            : getBackendUrl('https://{hostname}/', {
                hostname: window.location.hostname,
                isStat: true,
                ENV: {cordova: false}});

        this._apiService = new DesktopShareService(backendUrl);

        this._roomService = new RoomService(backendUrl);

        this._eventLogService = new EventLogService(statsApiUrl);

        this._browserUtilsService = new BrowserUtilsService(window);

        const pendingRoomGuid = this._browserUtilsService.getParamValue(prgUrlParamName);

        this._tsEnvironmentDetect = new TsEnvironmentDetect();

        this._brandingService = new BrandingService(
            this._apiService,
            this.subDomain
        );

        this._browserDetectService = new BrowserDetectService(
            this._tsEnvironmentDetect,
        );

        this._startController = new StartController(
            this._roomService,
            this._brandingService,
            this._browserDetectService,
            this._browserUtilsService,
            this._eventLogService,
            pendingRoomGuid
        );

        this._expiredController = new ExpiredController(this._browserUtilsService);

        this._withBrandingController = new WithBrandingController(this._brandingService);
    }

    get subDomain(): string {
        return getDeskopSharingSubdomain(window.location.origin, ENV.dev ? DEV_SUB_DOMAIN : '');
    }

    get startController(): IStartController {
        return this._startController;
    }

    get expiredController(): IExpiredController {
        return this._expiredController;
    }

    get withBrandingController(): IWithBrandingController {
        return this._withBrandingController;
    }
}
