/* eslint-disable global-require */
import {IDesktopShareService as IApiService} from '@techsee/techsee-client-infra/lib/services/DesktopShareService';
import {DEFAULT_BRANDING, IBrandingInfo} from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';

export interface IBrandingService {
    readonly guidPrefix: string,

    getBranding(): Promise<IBrandingInfo>,
    getDefaultBranding(): IBrandingInfo
}

export class BrandingService implements IBrandingService {
    private _guidPrefix = '';

    constructor(
        private _apiService: IApiService,
        private _subDomain: string
    ) {

    }

    get guidPrefix(): string {
        return this._guidPrefix;
    }

    async getBranding(): Promise<IBrandingInfo> {
        const defaultBranding = this.getDefaultBranding();

        if (this._subDomain) {
            return this._apiService.getBrandingBySubDomain(this._subDomain).then((data) => {
                this._guidPrefix = data.guidPrefix;

                return {
                    ...defaultBranding,
                    ...data.branding,
                    logo: require(`../_assets/images/${data.branding.logo}.png`)
                };
            });
        }

        return defaultBranding;
    }

    getDefaultBranding(): IBrandingInfo {
        return {
            ...DEFAULT_BRANDING,
            logo: require('../_assets/images/techsee.png')
        };
    }
}
