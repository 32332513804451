import { computed, observable, action } from 'mobx';
import { IBrandingService } from '../../services/BrandingService';
import { IBrandingInfo } from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';

export interface IWithBrandingController {
    readonly brandingLoading: boolean,
    readonly brandingInfo: IBrandingInfo,

    getBranding(): void
}

export class WithBrandingController implements IWithBrandingController {

    @observable private _brandingLoading = false;
    @observable private _brandingInfo!: IBrandingInfo;

    constructor(
        private _brandingService: IBrandingService,
    ) {
        this.getBranding = this.getBranding.bind(this);
    }

    @computed
    get brandingLoading(): boolean {
        return this._brandingLoading;
    }

    @computed
    get brandingInfo(): IBrandingInfo {
        return this._brandingInfo;
    }

    async getBranding(): Promise<void> {
        this._setBrandingLoading(true);

        try {
            // TODO: FIX
            //  const branding = await this._brandingService.getBranding();
            const branding = this._brandingService.getDefaultBranding();
            
            this._setBrandingInfo(branding);
        } catch (error) {
            const branding = this._brandingService.getDefaultBranding();

            this._setBrandingInfo(branding);
        } finally {
            this._setBrandingLoading(false);
        }
    }

    @action
    private _setBrandingLoading(state = false) {
        this._brandingLoading = state;
    }

    @action
    private _setBrandingInfo(branding: IBrandingInfo) {
        this._brandingInfo = branding;
    }
}
